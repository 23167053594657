import React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import Container from '../components/container'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';


export const query = graphql`
  query recentlyUpdatedPagesDynamic($startN:Int, $limitN:Int){
    strapi{ 
      siteSetting {
        site_name
        default_seo_description
      }
      pages(
        sort: "updated_at:desc"
        limit: $limitN
        start: $startN
        publicationState: LIVE
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          published_at
          parent {
            id
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
    }
  }
`

// markup
const LatestPages = props => {
  const {pageContext, data} = props
  const {messageContent} = pageContext
  const postNodes = data.strapi.pages


  return (
    <Layout>
      <MetaSchemaSEO
      />
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
      <Container>
        <h1>Latest Pages</h1>
        <div>{messageContent}</div>
        <ul>
            {postNodes.map(post => (
                <li key={post.id}>
                  <Link to={getPageUrl(post)}>{post.title}</Link>
                </li>
            ))}
        </ul>
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
    </Layout>
  )
}

export default LatestPages
